import { ckoApi } from '@noah-labs/fe-shared-data-access-cko';
import { api as dataAccessSharedApi } from '@noah-labs/fe-shared-data-access-shared';
import { api as dataAccessSigningApi } from '@noah-labs/fe-shared-data-access-signing';
import { setConfig as setDataAccessWalletConfig } from '@noah-labs/fe-shared-data-access-wallet';
import { setConfig as setFeatureAddressConfig } from '@noah-labs/fe-shared-feature-address';
import { setConfig as setFeatureAnalyticsConfig } from '@noah-labs/fe-shared-feature-analytics';
import { setConfig as setFeatureKycConfig } from '@noah-labs/fe-shared-feature-kyc';
import { setConfig as setFeatureLoggerConfig } from '@noah-labs/fe-shared-feature-logger';
import { setConfig as setFeatureSigningConfig } from '@noah-labs/fe-shared-feature-signing';
import { setConfig as setFeatureUserConfig } from '@noah-labs/fe-shared-feature-user';
import { setConfig as setFeatureWalletConfig } from '@noah-labs/fe-shared-feature-wallet';
import { setConfig as setUiComponentsConfig } from '@noah-labs/fe-shared-ui-components';
import { setConfig as setUiKycConfig } from '@noah-labs/fe-shared-ui-kyc';
import { setConfig as setUiSharedConfig } from '@noah-labs/fe-shared-ui-shared';
import { setConfig as setUiWalletConfig } from '@noah-labs/fe-shared-ui-wallet';
import type { TpCloudWebConfig } from './webConfigBrowser';

export function setModuleConfigs(webConfigBrowser: TpCloudWebConfig, isProd: boolean): void {
  const { cko, graphql } = webConfigBrowser;

  /**
   * Configure APIs and Imported modules
   */
  dataAccessSharedApi.configure(graphql);
  dataAccessSigningApi.configure(webConfigBrowser.signing.baseUrl);
  ckoApi.configure(cko.url, cko.publicKey);

  setFeatureAnalyticsConfig({
    ...webConfigBrowser.analytics,
    writeKey: webConfigBrowser.segment.writeKey,
  });

  setFeatureUserConfig({
    analytics: {
      cdnUrl: webConfigBrowser.analytics.cdnUrl,
    },
    commitHash: webConfigBrowser.commitHash,
    lnDomain: webConfigBrowser.lnDomain,
    segment: {
      writeKey: webConfigBrowser.segment.writeKey,
    },
    settings: {
      referralCodeParam: webConfigBrowser.settings.referralCodeParam,
    },
  });

  setUiComponentsConfig({
    enableTimers: webConfigBrowser.settings.enableTimers,
  });

  setUiSharedConfig({
    cko: webConfigBrowser.cko,
    manualRamp: webConfigBrowser.manualRamp,
  });

  setDataAccessWalletConfig({
    currencies: webConfigBrowser.currencies,
    isProd,
    manualRamp: webConfigBrowser.manualRamp,
    networkFee: webConfigBrowser.networkFee,
  });

  setFeatureSigningConfig({
    isProd,
  });

  setFeatureWalletConfig({
    appStage: webConfigBrowser.appStage,
    enableLoaderAnimations: webConfigBrowser.settings.enableLoaderAnimations,
    isProd,
    networkFee: webConfigBrowser.networkFee,
  });

  setFeatureAddressConfig({
    isProd,
  });

  setFeatureLoggerConfig({
    appStage: webConfigBrowser.appStage,
    commitHash: webConfigBrowser.commitHash,
    datadog: webConfigBrowser.datadog,
  });

  setFeatureKycConfig({
    kycExpirationAlertDays: 14,
    sumsub: webConfigBrowser.sumsub,
  });

  setUiWalletConfig({
    cko: {
      publicKey: webConfigBrowser.cko.publicKey,
      supportedFiatPaymentCards: webConfigBrowser.cko.supportedFiatPaymentCards,
    },
  });

  setUiKycConfig({
    settings: webConfigBrowser.settings,
  });
}
