import type { TpSardineEnvironment } from '@noah-labs/fe-shared-feature-sardine';
import { logger } from '@noah-labs/shared-logger/browser';
import type {
  AppConfigAnalytics,
  AppConfigAws,
  AppConfigCko,
  AppConfigCoreCurrencies,
  AppConfigFeatureRegions,
  AppConfigManualRamp,
} from '@noah-labs/shared-schema-gql';
import { AppStages } from '@noah-labs/shared-schema-gql';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export type TpCloudWebConfig = {
  analytics: AppConfigAnalytics & {
    apiHost: string;
    cdnUrl: string;
  };
  appStage: AppStages;
  auth: {
    oryCustomUrl: string;
  };
  aws: AppConfigAws;
  baseUrl: string;
  cko: {
    cardHolderNameMatchThreshold: string;
    feeMinimumFiatAmount: string;
    feePercentage: string;
    googlePayGateway: string;
    googlePayMerchantId: string;
    minimumFiatAmount: string;
    publicKey: string;
    slippagePercentage: string;
    supportedFiatPaymentCards: AppConfigCko['supportedFiatPaymentCards'];
    url: string;
  };
  commitHash: string;
  currencies: AppConfigCoreCurrencies;
  datadog: {
    enabled: boolean;
    token: string;
  };
  graphql: {
    apiKey: string;
    baseUrl: string;
    baseWsUrl: string;
    path: string;
    realtimePath: string;
  };
  lnDomain: string;
  manualRamp: AppConfigManualRamp;
  networkFee: {
    quoteExpirySec: number;
  };
  novu: {
    applicationId: string;
  };
  regions: AppConfigFeatureRegions;
  sardine: {
    clientId: string;
    environment: TpSardineEnvironment;
    scriptUrl: string;
  };
  segment: {
    writeKey: string;
  };
  settings: {
    enableLoaderAnimations: boolean;
    enableTimers: boolean;
    referralCodeParam: string;
  };
  signing: {
    baseUrl: string;
  };
  sumsub: {
    basicLevelName: string;
    eddLevelName: string;
    fullLevelName: string;
  };
};

// https://day.js.org/docs/en/plugin/loading-into-nodejs
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

function getWebConfigBrowser(): TpCloudWebConfig {
  if (!process.env.GATSBY_FE_WEB_CONFIG) {
    logger.fatal('GATSBY_FE_WEB_CONFIG not found');
  }

  return process.env.GATSBY_FE_WEB_CONFIG as unknown as TpCloudWebConfig;
}

export const webConfigBrowser = getWebConfigBrowser();

export const isProd = webConfigBrowser.appStage === AppStages.Prod;
