import { forwardRef } from 'react';
import { Alert } from '@mui/material';
import type { AlertProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { closeSnackbar } from 'notistack';

type PpCustomSnackbar = AlertProps & {
  dismissable?: boolean;
  id: string;
  message: string | React.ReactNode;
};

export const AlertSnackbar = forwardRef<HTMLDivElement, PpCustomSnackbar>(
  ({ dismissable, id, message, ...rest }, ref) => {
    const theme = useTheme();

    const dismissableAlertStyle = {
      '.MuiAlert-icon': {
        marginTop: theme.spacing(0.5),
      },
    };

    return (
      <div ref={ref} role="alert">
        <Alert
          data-qa={id}
          sx={dismissable ? dismissableAlertStyle : null}
          onClose={
            dismissable
              ? (): void => {
                  closeSnackbar(id);
                }
              : undefined
          }
          {...rest}
        >
          {message}
        </Alert>
      </div>
    );
  },
);
AlertSnackbar.displayName = AlertSnackbar.name;
